.b-back {
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 3.8vw;
	display: block;

	svg {
		display: inline-block;
		width: 2vw;
		height: .7vw;
		margin-bottom: .2vw;
	}

	~ ._text {
		display: block;
		font-size: .65vw;
		color: #939598;
		letter-spacing: .07vw;
		text-transform: uppercase;

		a {
			color: #939598;
			text-decoration: none;
		}
	}
}
