.b-more-list {
	font-size: 0;

	&._is-publish {
		display: flex;
		justify-content: space-between;

		.b-pub_place_more {
			width: 32.33%;
		}


		.b-pub_type_image + .b-pub_type_border {

			&::before {
				display: block;
			}
		}
	}
}



