.b-lang__item {
	display: none;
	vertical-align: middle;
	text-transform: uppercase;
	text-decoration: none;
	color: #231f20;
	font-size: .8vw;
	letter-spacing: .03vw;

	&._is-active {
		display: inline-block;
	}
}