.b-more__link {
	display: inline-block;
	text-decoration: none;

	svg {
		transition: transform .9s;
	}

	&:hover {
		svg {
			transform: rotate(720deg);
		}
	}
}