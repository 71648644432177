.b-search__input {
	width: 77%;
	border: 0;
	border-bottom: 1px solid #b3b3a8;
	text-transform: uppercase;
	font-style: italic;
	font-size: .85vw;
	color: @black;
	padding-bottom: .05vw;
	letter-spacing: .1vw;
}
