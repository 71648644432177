.b-form__textarea {
	width: 100%;
	border: 1px solid #bcbec0;
	height: 2vw;
	font-size: 1vw;
	background: 0 0;
	text-transform: uppercase;
	padding: 2vw;

	&::-webkit-input-placeholder {
		font-style: italic;
		font-weight: 100;
		color: #959799;
	}

	&:-moz-placeholder { /* Firefox 18- */
		font-style: italic;
		font-weight: 100;
		color: #959799;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
		font-style: italic;
		font-weight: 100;
		color: #959799;
	}

	&:-ms-input-placeholder {
		font-style: italic;
		font-weight: 100;
		color: #959799;
	}
}