.b-branding {
	position: relative;
	text-align: center;
	height: 42vw;
	overflow: hidden;
	margin-top: 6.5vw;
	margin-bottom: 10vw;
	perspective: 1000px;
	transform-style: preserve-3d;
}
