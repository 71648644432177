.b-skills__item {
	width: 32.33%;
	margin-right: 1%;

	.b-title_type_3 {
		position: relative;
	}

	._bottom {
		position: absolute;
		bottom: .4vw;
		left: 0;
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin: 0;
		li {
			font-size: 1.04vw;
			line-height: 1.55;
			margin: .1vw 0;
			padding-right: 6%;
			color: #58595b;
		}
	}
}