.b-products__page-link {
	text-decoration: none;
	color: #dedfe0;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	transition: color @short;

	&:last-child {
		left: -.3vw;

		&:first-letter {
			text-transform: lowercase;
		}
	}

	&::after {
		position: absolute;
		bottom: .2vw;
		left: 0;
		content: '';
		height: 4px;
		width: 100%;
		background: url('../images/dashed.svg') repeat center center transparent;
		display: block;
	}

	&:hover {
		color: #d9d9ce;

		&::after {
			background: url('../images/dashed_dark.svg') repeat center center transparent;
		}
	}

	&._is-active {
		color: @black;
		border-bottom-color: transparent;

		&::after {
			display: none;
		}
	}
}


