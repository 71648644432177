.b-product {
	position: relative;
	width: 50%;
	height: 27.8vw;
	text-decoration: none;
	overflow: hidden;

	&:hover {
		.b-product__preview {
			transform: scale3d(1.1, 1.1, 1.1);
		}
	}
}