.b-request__form {
	.b-form__wrapper {
		margin-bottom: 1.4vw;
	}

	.b-btn_type_contour {
		margin-top: 9vw;
		width: 48%;
		border-color: #bcbec0;
		background: transparent;
		font-style: italic;
		font-weight: 100;
	}
}






