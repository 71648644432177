.b-news {
	.b-date {
		padding-left: 6%;
	}

	.b-text-box_mb_small {
		margin-top: 1.8vw;
		margin-bottom: 1.6vw;
		width: 64%;
		padding-left: 6%;


		.b-title_type_1 {
			line-height: 1;
			padding-bottom: 1.1vw;
			margin-bottom: 1.1vw;
			border-bottom: 1px solid #e6e7e8;
		}
	}
	
	.b-wysiwyg {
		overflow: hidden;
		margin-bottom: 3.4vw;
		p, ul, h1, h2, h3, h4, h5, blockquote {
			padding: 0 6%;
			width: 69%;
		}


		blockquote {
			font-weight: 600;
			font-size: 1vw;
			font-style: italic;
			padding: 0 9%;
			line-height: 1.7;
			text-transform: uppercase;
			margin-bottom: 1.3vw;
			display: block;
			color: @black;
		}
		p {
			font-size: 1.03vw;
			line-height: 1.5;
			margin-bottom: 1.4vw;
		}
	}
}


