.b-btn {
	display: inline-block;
	vertical-align: middle;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	padding: 1.15vw 2.55vw;
	font-size: 1.02vw;
	text-align: center;
	transition: all @short;
}