.b-info__opt {
	list-style: none;
	padding: 0;
	margin-top: 2.4vw;

	&._single {
		margin-top: 5.8vw;
	}
}


