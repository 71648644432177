.b-pagination__link {
	font-size: 1.2vw;
	display: inline-block;
	vertical-align: middle;
	font-weight: 100;
	text-decoration: none;
	color: #808285;
	margin: 0 .4vw;



	&._is-active {
		font-weight: 900;
		color: @black;
	}
}

