.b-search__output {
	border-bottom: 1px solid #e6e7e8;
	font-size: 1.12vw;
	padding-bottom: 1vw;
	padding-top: 2vw;

	header {
		display: flex;
		justify-content: space-between;
	}
	strong {
		color: #000;
		line-height: 1.45;
		font-weight: 900;
	}
	a {
		text-decoration: none;
	}

	p {
		margin-top: .1vw;
		line-height: 1.7;
		color: @black;
	}
	.b-pub__date {
		display: block;
		margin-bottom: 1.2vw;
		font-size: .65vw;
		letter-spacing: .1vw;
	}
}