.b-filters__link {
	text-transform: uppercase;
	text-decoration: none;
	margin-right: 1.4vw;
	font-size: .9vw;
	color: @foot_text_gray;

	&._is-active {
		color: #231f20;
		font-weight: 600;
	}
}