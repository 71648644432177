/* iCheck plugin Minimal skin, black
----------------------------------- */
.icheckbox_minimal {
	position: relative;
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 1.55vw;
    height: 1.55vw;
	border: 1px solid #a7a9ac;
    cursor: pointer;

	label {
		display: inline-block;
		vertical-align: middle;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: none;
		display: none;
		transform: translateX(.3vw);
	}
}



.icheckbox_minimal {
    background-position: 0 0;
}
.icheckbox_minimal.hover {
}
.icheckbox_minimal.checked {
	&::after {
		background: url(../images/check.svg) no-repeat center center transparent;
		background-size: contain;
		display: block;
	}
}

.icheckbox_minimal.disabled {
	cursor: default;
}
.icheckbox_minimal.checked.disabled {
}
