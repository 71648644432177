.b-success-msg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #F0F0EB;
	color: @black;
	text-align: center;
	visibility: hidden;
	opacity: 0;
	transition: all @short;

	&::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
		width: 0;
	}

	&__box {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		font-size: 1vw;
	}

	&__header {
		font-size: 2.2vw;
		font-weight: 900;
		letter-spacing: -.08vw;
		margin-bottom: 1.7vw;
	}
	&__text {
		font-size: 1.02vw;
		line-height: 1.4;
		color: #58595B;

		span {
			display: block;
		}
	}

	.back-link {
		position: absolute;
		bottom: 3vw;
		left: 50%;
		color: #58595B;
		text-decoration: none;
		transform: translate(-50%, 0);
		font-size: 1.02vw;

		svg	 {
			vertical-align: middle;
			width: 2vw;
			height: 100%;
			margin-right: .5vw;
			margin-top: .05vw;
		}
	}

	.back-text {
		display: inline-block;
		vertical-align: middle;
		border-bottom: 1px solid #A7A9AC;
	}
}
