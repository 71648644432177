.b-products__descr {
	width: 50%;
	margin-top: 4.5vw !important;
	margin-bottom: 6vw !important;

	.b-wysiwyg {
		p, span {
			font-size: 1.03vw !important;
			line-height: 1.5 !important;
			color: @t_gray !important;
		}
	}

}