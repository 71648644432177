.b-slides__carousel {
	height: 100%;

	.owl-stage-outer {
		height: 100%;
	}
	.owl-stage {
		height: 100%;
	}
	.owl-item {
		height: 100%;
	}
}