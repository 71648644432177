.b-pub {
	position: relative;
	overflow: hidden;
	height: 10.2vw;

	&__source-link {
		max-width: 5vw;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
		overflow: hidden;
		vertical-align: middle;
		color: #939598;
		text-decoration: none;
		position: relative;
		z-index: 10;

		svg {
			display: inline-block;
			vertical-align: middle;
			width: .9vw;
			height: 100%;
		}
	}
}


