.b-pagination__prev {
	display: inline-block;
	vertical-align: middle;
	margin-right: 1vw;

	svg {
		width: 4vw;
		height: 1vw;
	}
}

