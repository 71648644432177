.b-request {
	position: relative;
	background: #ededeb;
	padding: 4.1vw 6vw;
	font-size: 0;

	&._is-success {
		.b-success-msg {
			opacity: 1;
			visibility: visible;
		}
	}

	.i-column {
		width: 50%;
	}
}
