.b-wysiwyg {
	p {
		font-size: .9vw;
	}
	i {
		font-style: italic;
		font-size: .7vw;
	}
	iframe {
		width: 100%;
		height: 35vw;
	}
	img {
		max-width: 100%;
	}

	a {
		text-decoration: none;
		color: #000;
		border-bottom: 1px solid #939598;
	}

	ins {
		display: block;
		font-size: 0.65vw;
		line-height: 1.9;
		color: #6d6e71;
		font-style: normal;
		text-decoration: none;
	}
}