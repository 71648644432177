.b-footer__map-link {
	position: relative;
	top: .1vw;
	color: @foot_text_gray;

	.symbol {
		position: absolute;
		top: -.1vw;
		left: -2.2vw;
		width: 1.7vw;

		svg {
			display: inline-block;
			width: 100%;
			height: 100%;
		}
	}
}