.b-footer__copy {
	width: 100%;
	color: @foot_text_gray;
	font-size: .9vw;
	margin-left: 7.5vw;
	position: relative;
	line-height: 1.4;

	._link {
		color: @foot_text_gray;
	}

	.symbol {
		position: absolute;
		left: -1.1vw;
		margin-right: .5rem;
	}
}