.b-product-detail {
	.b-text-box_mb_small {
		font-size: 0;
		margin-top: 2vw;
		margin-bottom: 4.7vw;

		.b-wysiwyg {
			width: 70%;
		}



		.i-column {
			&:first-child {
				width: 74.5%;
			}
			&:last-child {
				width: 25.5%;
			}
		}

		.b-title_type_1 {
			margin-top: 0;
			margin-bottom: 1.9vw;
			line-height: 1;
			padding-right: 3vw;
		}
	}
}