.b-404 {
	&__title {
		color: #FF3636;
		font-size: 3.6vw;
		margin-top: 7vw;
		margin-bottom: 1.2vw;
	}

	&__descr {
		color: #58595B;
		font-size: 1.1vw;
	}

	a {
		border-bottom: 1px solid;
		text-decoration: none;
		line-height: .7;
		color: #58595B;
	}

	.b-branding__parallax {
		background-image: url('../images/404.jpg');
	}
}

