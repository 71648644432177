.b-date {
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 4.2vw;
	display: inline-block;
	font-size: .65vw;
	letter-spacing: .06vw;
	color: #939598;

	&::after {
		content: '';
		display: block;
		width: 30%;
		height: 1px;
		background: #e6e7e8;
		margin-top: .55vw;
	}
}
