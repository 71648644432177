.b-publications {
	.b-pubs {
		.b-title_type_4 {
			font-size: 3vw;
		}
		margin-top: .7vw;

		.b-pub {
			&:last-child {
				border-bottom: 1px solid #e6e7e8;
			}
		}
	}
}