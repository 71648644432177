.b-pubs {
	font-size: 0;
	margin-top: 1.7vw;
	display: flex;
	justify-content: space-between;

	.i-column {
		font-size: 1vw;
		width: 33.333%;

		&:nth-child(1) {
			.b-pub_type_border {
				margin-right: 2.5%;
			}
		}
		&:nth-child(2) {
			.b-pub_type_border {
				margin-left: 2.5%;
				margin-right: 2.5%;
			}
			.b-pub_type_image {
				margin-right: 2.5%;
			}
			.b-title_type_4 {
				margin-left: 2.5%;
			}
		}
		&:nth-child(3) {
			.b-pub_type_border {
				margin-left: 2.5%;
			}
			.b-title_type_4 {
				margin-left: 2.5%;
			}
		}
	}

	.b-title_type_4 {
		padding-bottom: .2vw;
		margin: 1.7vw 0 0;
	}
}
