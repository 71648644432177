.b-header {
	position: relative;
	height: 9.5vw;
	width: 100%;
	text-align: right;
	transition: background @long;

	.i-inner-wrapper {
		height: 100%;
		border-bottom: 1px solid #e6e7e8;

		&::before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}
	}

	.b-search {
		width: 15vw;
		margin: 0 4.4vw 0 2.8vw;
	}
}
