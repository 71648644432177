.b-people__avatar {
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 22vw;

	img {
		width: 100%;

		&._is-active {
			display: block;
		}
		display: none;
	}
}


