.b-footer {
	padding: 3.5vw 0 7vw;
	display: flex;
	justify-content: space-between;
	background: @bg_gray;
	font-size: .87vw;
	color: @foot_text_gray;
	margin-top: 6vw;

	a {
		text-decoration: none;
		padding-bottom: 0;
		border-bottom: 1px solid #bcbec0;
		transition: color @short;

		&:hover {
			color: #414042;
			border-bottom-color: transparent;
		}
	}

	.i-inner-wrapper {
		padding: 0 6.4vw;
	}

	.i-column {
		width: 33%;

		&:nth-child(2) {
			padding-left: .3vw;
		}
		&:last-child {
			line-height: 1.7;
			padding-left: .5vw;
		}
	}


	.b-nav {
		display: block;

		&__item {
			display: block;
			margin-bottom: .4vw;
		}
		&__link {
			display: inline-block;
			font-size: .9vw;
			font-weight: 400;
			color: @foot_text_gray;
			padding-left: 0;

			&::after {
				content: '';
				display: block;
			}
			&:hover {
				color: #414042;
				border-bottom-color: transparent;
			}
		}
	}

	.b-social {
		margin-left: 7.5vw;
		margin-top: .4vw;

		&__fb {
			position: relative;
			color: @foot_text_gray;


			svg {
				position: absolute;
				left: -2vw;
				overflow: visible;
				width: 1.3vw;
				height: 1.3vw;
			}
		}
	}

}
