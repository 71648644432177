.b-pub__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: block;
	max-width: 100%;
	min-height: 100%;
	opacity: 1;
	transform-origin: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	transition: transform @long, opacity @long;
	transform: scale3d(1.05,1.05,1.05);
}