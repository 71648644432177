/* Dropdown control */
.selectBox-dropdown {
    min-width: 150px;
    text-decoration: none;
    text-align: left;
    color: #000;
    outline: none;
	border: 1px solid #ccc;
	cursor: pointer;
	letter-spacing: .1vw;
	display: block;
	margin: .6vw 0;
	width: 21.6vw !important;
	max-width: 30%;
	z-index: 1;
	position: absolute;
	padding: 1vw 1.6vw;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
}

.selectBox-dropdown:focus,
.selectBox-dropdown:focus .selectBox-arrow {
    border-color: #ccc;
}

.selectBox-dropdown .selectBox-label {
	text-transform: uppercase;
	color: @black;
	font-size: .9vw;
	display: block;
    white-space: nowrap;
    overflow: hidden;
}

.selectBox-dropdown .selectBox-arrow {
	height: 1vw;
	width: 1.3vw;
	position: absolute;
	top: 50%;
	right: 1.2vw;
	background: url('../images/selecter-arrow.svg') no-repeat center center transparent;
	background-size: cover;
	content: '';
	display: block;
	transform: translate(0, -50%);
	margin: auto 0;
}

/* Dropdown menu */
.selectBox-dropdown-menu {
    position: absolute;
    z-index: 99999;
    max-height: 16vw;
    min-height: 1em;
	border: 1px solid #ccc;
    background: #FFF;
    overflow: auto;
	width: 21.6vw !important;
	max-width: 30%;
    -webkit-overflow-scrolling: touch;
}

.selectBox-options LI {
	&:first-child {
		display: none;
	}
}

/* Inline control */
.selectBox-inline {
    min-width: 150px;
    outline: none;
    border: solid 1px #BBB;
    background: #FFF;
    display: inline-block;
    overflow: auto;
}

.selectBox-inline:focus {
    border-color: #666;
}

/* Options */
.selectBox-options,
.selectBox-options LI,
.selectBox-options LI A {
    list-style: none;
    display: block;
    cursor: default;
    padding: 0;
    margin: 0;
}

.selectBox-options li a {
	padding: 1vw 1.6vw;
	text-transform: uppercase;
	font-size: .9vw;
	color: #939598;
	cursor: pointer;
}

.selectBox-options.selectBox-options-top{
    border-bottom:none;
	margin-top:1px;
}
.selectBox-options.selectBox-options-bottom{
	border-top:none;
}

.selectBox-options LI A {
    white-space: nowrap;
    overflow: hidden;
}

.selectBox-options LI.selectBox-hover A {
    color: @black;
}


.selectBox-options LI.selectBox-selected A {
    background-color: @white;
	padding: 1vw 1.6vw;
	color: #000;
	cursor: pointer;
	display: block;
	font-size: .9vw;
	letter-spacing: .1vw;
	text-transform: uppercase;
}
