.b-facebook {
	padding: 1.2vw 0;

	.b-pub {
		&__date {
//			position: absolute;
//			right: 0;
//			top: 1.6vw;
		}
		&__content {
			padding: 0 0 0 20%;
			overflow: visible;
		}
	}
}
