.b-product_type_small {
	display: inline-block;
	vertical-align: top;
	width: 33.33%;
	height: 18.7vw;

	.b-product {
		&__name {
			font-size: 1vw;
		}
		&__info {
			width: 50%;
			height: 9.9vw;
			text-align: left;
			padding: .6vw 1.3vw;
		}

	}
}
