.b-map__mail {
	display: inline-block;
	text-decoration: none;
	color: @white;
	border-bottom: 1px solid #808285;
	padding-bottom: 0;
	position: relative;
	top: -.2vw;
}

