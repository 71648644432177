@font-face {
    font-family: 'geometria';
    src: url('../fonts/Geometria/Italic/brownfox_-_geometria_italic-webfont.eot');
    src: url('../fonts/Geometria/Italic/brownfox_-_geometria_italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Geometria/Italic/brownfox_-_geometria_italic-webfont.woff2') format('woff2'),
    url('../fonts/Geometria/Italic/brownfox_-_geometria_italic-webfont.woff') format('woff'),
    url('../fonts/Geometria/Italic/brownfox_-_geometria_italic-webfont.ttf') format('truetype'),
    url('../fonts/Geometria/Italic/brownfox_-_geometria_italic-webfont.svg#geometriaitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'geometria';
    src: url('../fonts/Geometria/Regular/brownfox_-_geometria-webfont.eot');
    src: url('../fonts/Geometria/Regular/brownfox_-_geometria-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Geometria/Regular/brownfox_-_geometria-webfont.woff2') format('woff2'),
    url('../fonts/Geometria/Regular/brownfox_-_geometria-webfont.woff') format('woff'),
    url('../fonts/Geometria/Regular/brownfox_-_geometria-webfont.ttf') format('truetype'),
    url('../fonts/Geometria/Regular/brownfox_-_geometria-webfont.svg#geometriaregular') format('svg');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'geometria';
    src: url('../fonts/Geometria/Medium/brownfox_-_geometria_medium-webfont.eot');
    src: url('../fonts/Geometria/Medium/brownfox_-_geometria_medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Geometria/Medium/brownfox_-_geometria_medium-webfont.woff2') format('woff2'),
    url('../fonts/Geometria/Medium/brownfox_-_geometria_medium-webfont.woff') format('woff'),
    url('../fonts/Geometria/Medium/brownfox_-_geometria_medium-webfont.ttf') format('truetype'),
    url('../fonts/Geometria/Medium/brownfox_-_geometria_medium-webfont.svg#geometria_mediummedium') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
	font-family: 'geometria';
	src: url('../fonts/Geometria/MediumItalic/Brownfox - Geometria Medium Italic-webfont.eot');
	src: url('../fonts/Geometria/MediumItalic/Brownfox - Geometria Medium Italic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Geometria/MediumItalic/Brownfox - Geometria Medium Italic-webfont.woff2') format('woff2'),
	url('../fonts/Geometria/MediumItalic/Brownfox - Geometria Medium Italic-webfont.woff') format('woff'),
	url('../fonts/Geometria/MediumItalic/Brownfox - Geometria Medium Italic-webfont.ttf') format('truetype'),
	url('../fonts/Geometria/MediumItalic/Brownfox - Geometria Medium Italic-webfont.svg#geometria_mediummedium_italic') format('svg');
	font-weight: 600;
	font-style: italic;

}
@font-face {
    font-family: 'geometria';
    src: url('../fonts/Geometria/ExtraBold/extrabold.eot');
    src: url('../fonts/Geometria/ExtraBold/extrabold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Geometria/ExtraBold/extrabold.woff2') format('woff2'),
    url('../fonts/Geometria/ExtraBold/extrabold.woff') format('woff'),
    url('../fonts/Geometria/ExtraBold/extrabold.ttf') format('truetype'),
    url('../fonts/Geometria/ExtraBold/extrabold.svg#geometria_extraboldextrabold') format('svg');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'geometria';
    src: url('../fonts/Geometria/LightItalic/brownfox_-_geometria_light_italic-webfont.eot');
    src: url('../fonts/Geometria/LightItalic/brownfox_-_geometria_light_italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Geometria/LightItalic/brownfox_-_geometria_light_italic-webfont.woff2') format('woff2'),
    url('../fonts/Geometria/LightItalic/brownfox_-_geometria_light_italic-webfont.woff') format('woff'),
    url('../fonts/Geometria/LightItalic/brownfox_-_geometria_light_italic-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'geometria';
    src: url('../fonts/Geometria/ExtraBoldItalic/extrabold.eot');
    src: url('../fonts/Geometria/ExtraBoldItalic/extrabold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Geometria/ExtraBoldItalic/extrabold.woff2') format('woff2'),
    url('../fonts/Geometria/ExtraBoldItalic/extrabold.woff') format('woff'),
    url('../fonts/Geometria/ExtraBoldItalic/extrabold.ttf') format('truetype'),
    url('../fonts/Geometria/ExtraBoldItalic/extrabold.svg#geometria_extraboldXBdIt') format('svg');
    font-weight: 900;
    font-style: italic;

}
