.b-slides__info {
	position: absolute;
	bottom: 0;
	left: 6%;
	width: 44.1%;
	height: 50%;
	padding-left: 7.5vw;
	padding-right: 3vw;
	padding-top: 2.5vw;
	background: rgba(0, 0, 0, .79);
	z-index: 100;

	.b-pub__title {
		position: absolute;
		top: 8.5vw;
		left: 7.5vw;
		font-size: 2vw;
		color: @white;
		font-weight: 900;
		line-height: 1;
		padding-right: 10vw;
		height: 6vw;
		overflow: hidden;
		text-decoration: none;
		letter-spacing: -.08vw;

		a {
			text-decoration: none;
			color: @white;
			display: block;
			transition: color @short;
			&:hover {
				color: #d1d1c7;
			}
		}
	}


	.b-pub__date {
		font-size: .6vw;
		letter-spacing: .12vw;
		color: #a7a9ac;
	}

	.owl-next {
		position: absolute;
		right: 3.3vw;
		top: 55%;
		width: 1.1vw;
		height: 1.6vw;
		cursor: pointer;
		z-index: 101;
		transform: translate(0, -50%);
		background: url('../images/carousel/arrow-right.svg') no-repeat 0 0 transparent;
		background-size: contain;
	}
	.owl-prev {
		position: absolute;
		left: 3.5vw;
		top: 55%;
		width: 1.1vw;
		height: 1.6vw;
		z-index: 101;
		cursor: pointer;
		transform: translate(0, -50%);
		background: url('../images/carousel/arrow-left.svg') no-repeat 0 0 transparent;
		background-size: contain;
	}

	.js-owl-dots {
		position: absolute;
		right: 3.3vw;
		top: 2.5vw;
	}
	.owl-dot {
		display: inline-block;
		vertical-align: middle;
		width: .75vw;
		height: .75vw;
		margin-left: .55vw;
		position: relative;
		background: url('../images/carousel/dot.svg') no-repeat 0 0 transparent;
		background-size: contain;
		overflow: visible;
		cursor: pointer;


		&.active {
			background: url('../images/carousel/dot-active.svg') no-repeat 0 0 transparent;
			background-size: contain;
		}
	}
}

