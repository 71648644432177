.b-skills {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 6vw auto;

	.b-title_type_3 {
		display: inline-block;
		vertical-align: bottom;
		width: 100%;
		height: 4.3vw;
		padding-bottom: .4vw;
		border-bottom: 1px solid #e6e7e8;
	}

	p {
		font-size: 1vw;
		line-height: 1.6;
		margin: .1vw 0;
		padding-right: 6%;
	}
}