.b-pub_type_border {
	transition: border-color @long;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: #e6e7e8;
		transition: background @long;
	}
}

.no-touchevents {
	.b-pub_type_border {
		&:hover {
			&::before {
				background: @black;
			}
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			background: url(../images/fade.png) repeat-x center bottom transparent;
			height: 2vw;
			width: 100%;
			z-index: 5;
		}
	}
}
