.b-search-page {
	font-size: 0;

	.b-search {
		width: 27.2vw;

		&__submit {
			margin-top: 0;
		}
	}
	.b-title_type_1 {
		margin-top: 0;
		margin-bottom: 1.9vw;
	}
	.b-text-box_mb_small {
		margin-top: 1.3vw;
		margin-bottom: 4.4vw;
		letter-spacing: -.05vw;
	}
	.b-back {
		margin-top: 4.2vw;

		svg {
			position: relative;
			top: -.2vw;
			left: .1vw;
			width: 1vw;
			height: auto;
			margin-bottom: 0;
		}
	}
	.i-column {
		&:first-child {
			width: 66%;
		}
		&:last-child {
			width: 34%;
		}
	}

}

