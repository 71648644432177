.b-footer__link-wrapper {
	margin: .8vw 0;

	&:first-child {
		margin-top: 0;
	}
}

@media screen and (max-width: 567px) {
	.b-footer__link-wrapper {
		margin: 1.18rem 0;
		line-height: 1.3;
	}
}