.b-search__filters-link {
	display: inline-block;
	vertical-align: middle;
	font-weight: 100;
	font-style: italic;
	font-size: 1vw;
	text-transform: uppercase;
	text-decoration: none;
	padding: .9vw 3.8vw;
	color: #808285;
	letter-spacing: .05vw;

	&:first-child {
		padding-left: 0;
		padding-right: 3vw;
	}

	&._date {
		border-left: 1px solid #d1d3d4;
		padding-right: 0;
		padding-left: 2.5vw;

		svg {
			display: inline-block;
			vertical-align: middle;
			width: .9vw;
			height: .9vw;
		}
	}


	&._is-active {
		color: @black;
		font-weight: 400;
	}
}