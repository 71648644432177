.b-more {
	padding-top: 3vw;
	text-align: center;

	svg {
		width: 7vw;
		display: inline-block;
		margin: auto;
		height: 7vw;
		overflow: visible;
	}
}


