.b-product__preview {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	transform: scale3d(1, 1, 1);
	transition: transform @long;
}

