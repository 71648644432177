.b-info__item {
	font-size: .8vw;
	font-weight: 400;
	color: #6d6e71;
	letter-spacing: .1vw;
	margin-bottom: .4vw;
	text-transform: uppercase;

	strong {
		font-weight: 400;
		color: @black;
		text-transform: none;
		font-size: 1vw;

		a {
			color: @black;
			text-decoration: none;
			border-bottom: 1px solid #939598;
		}
	}
}