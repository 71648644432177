.b-header__logo {
	position: absolute;
	left: 6%;
	top: 50%;
	transform: translate(0, -50%);
	width: 6.5vw;
	height: 3.5vw;
	line-height: 1;

	svg {
		width: 100%;
		height: 100%;
		overflow: visible;
	}
}
