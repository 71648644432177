.b-map__info {
	position: absolute;
	bottom: 0;
	left: 6%;
	height: 21vw;
	width: 44%;
	background: rgba(0, 0, 0, .8);
	color: @white;
	padding: 1.6vw 1.8vw;
	line-height: 1.5;
	font-size: 1.29vw;
	font-weight: 900;
	z-index: 100;

	.b-btn-group {
		position: absolute;
		width: 92%;
		bottom: 1.6vw;

		.b-btn {
			color: #f1f2f2;
			border-color: #939598;
			font-weight: 100;
			font-style: italic;
			letter-spacing: .1vw;

			&:hover {
				color: #6d6e71;
				background: #f0f0eb;
				border-color: #f0f0eb;
			}
		}
	}
}