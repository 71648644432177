.b-short-info {
	background: @bg_gray;
	font-size: 0;
	padding: 2vw 4vw 3vw 7.5vw;

	.i-column {
		width: 45%;
		font-size: 1.1vw;
		margin-right: 2.5%;
		padding-right: 3vw;

		&:last-child {
			margin-right: 0;
			padding-left: .8vw;
			padding-right: 0;
		}
	}
	.b-title_type_4 {
		margin: 2vw 0 .2vw;
	}

	.b-wysiwyg {
		i {
			text-transform: uppercase;
			font-size: .7vw;
		}
		em {
			text-transform: uppercase;
			font-size: .7vw;
		}
		p {
			font-size: .86vw;
			margin: 0;
			line-height: 1.6;
		}
	}
}


