.b-carousel {
	padding-left: 6%;
	margin-top: 3.3vw;

	.slick-prev {
		position: absolute;
		top: 46.5%;
		left: 10%;
		width: 4vw !important;
		height: 4vw;
		cursor: pointer;
		padding: 0 !important;
		background: url('../images/arrow.svg') no-repeat center center transparent;
		background-size: contain;
		transform: translate(0, -50%);
		z-index: 100;
	}
	.slick-next {
		position: absolute;
		top: 46.5%;
		right: 4%;
		width: 4vw !important;
		height: 4vw;
		padding: 0 !important;
		cursor: pointer;
		background: url('../images/arrow.svg') no-repeat center center transparent;
		background-size: contain;
		transform: translate(0, -50%) rotate(180deg);
		z-index: 100;
	}
	.slick-counter {
		font-weight: 900;
		font-size: 1.7vw;
		display: block;
		padding: 1vw 0;

		._slash {
			padding: 0;
			font-weight: 400;
			color: #808285;
			font-size: 2vw;
			vertical-align: middle;
			margin-left: -.2vw;
			width: 1vw;
			height: 2vw;
			margin-right: -.2vw;
			background: url(../images/carousel/slash.svg) no-repeat 0 0 transparent;
			background-size: contain;
			display: inline-block;
		}
	}
}


