.b-branding__parallax {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 60vw;
	background-image: url('../images/branding.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	transform: translate3d(0, 0, 0);
}