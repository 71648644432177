html, body {
	font-size: 10px;
	font-family: geometria, arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}

body {
	box-sizing: border-box;
}

h1 {
	font-size: 1vw;
}
h2 {
	color: @black;
	font-size: 1vw;
}
p {
	font-size: .9vw;
	color: @t_gray;
	line-height: 1.45;
}

figure {
	margin: 0;
}

input {
	-webkit-appearance: none;
	border-radius: 0;
}
textarea {
	-webkit-appearance: none;
	border-radius: 0;
}

.i-column {
	display: inline-block;
	vertical-align: top;
}
*, *:before, *:after {
	box-sizing: inherit;
}
*:focus {
	outline: none;
}

.i-inner-wrapper {
	width: 88%;
	margin: auto;
}
.i-column {
	display: inline-block;
	vertical-align: top;
}

