.i-divider {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 0.9vw;
	left: -.1vw;
	margin-left: .2vw;
	margin-right: .1vw;

	svg {
		width: 2vw;
		display: inline-block;
		height: 100%;
	}
}

