.b-nav__link {
	color: @black;
	text-decoration: none;
	height: 100%;
	display: inline-block;
	vertical-align: middle;
	font-size: 1.17vw;
	font-weight: 900;
	padding: 0 2.14vw 0;
	transition: color @short;
	text-transform: lowercase;

	&:last-child {
		padding-right: 0;
	}

	&._is-active {
		color: #bbbdbf;
	}
}


.no-touchevents {
	.b-nav__link {
		transition: color @short;

		&:hover {
			color: #bbbdbf;
		}
	}
}
