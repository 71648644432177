.b-search__submit {
	display: inline-block;
	vertical-align: middle;
	border: 0;
	padding: 0 .2vw;
	background: @white;
	margin-top: .3vw;

	svg {
		overflow: visible;
		width: 1vw;
		position: relative;
		top: 0;
		margin-left: .2vw;
	}
}