.b-product-form {
	position: relative;
	font-size: 0;
	margin: 6vw 0 4.3vw;

	&._is-success {
		.b-success-msg {
			opacity: 1;
			visibility: visible;
		}
	}

	&._is-active {
		.b-product-form__fields {
			overflow: visible;
			opacity: 1;
			transform: scale3d(1, 1, 1);
		}
		.b-product-form__btn {
			._submit {
				display: inline-block;
			}
			._request {
				display: none;
			}
		}
	}

	.b-product-form__btn {
		._submit {
			display: none;
		}
		._request {
			display: inline-block;
		}
	}

	.b-btn_type_contour {
		letter-spacing: .1vw;
		width: 32.5%;
		border-color: #bcbec0;
		font-style: italic;
		font-weight: 100;
		color: @black;
		padding: 1.4vw 2.55vw;
		font-size: 1.25vw;

		&:hover {
			background: #f0f0eb;
			border-color: #f0f0eb;
			color: #939598;
		}
	}

	.b-form {
		&__wrapper {
			margin-bottom: 1.2vw;
		}
		&__input-group .b-form__input {
			width: 49%;
		}
		&__textarea {
			height: 20.05vw;
		}
	}

	.i-column {
		width: 49.3%;

		&:first-child {
			margin-right: 1.4%;
		}
	}

}