.b-request__inner-checkboxes {
	padding-left: 2.8vw;
	margin-top: 1.3vw;
	margin-bottom: 1.3vw;


	.b-form__wrapper {
		margin-bottom: .75vw;
	}
	.icheckbox_minimal {
		width: 1.1vw;
		height: 1.1vw;

		&::after {
			transform: translateX(.2vw);
		}
	}
}


