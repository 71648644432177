.b-pub_type_image {
	padding: 0 2vw;

	.b-pub__title {
		font-weight: 600;
		color: @white;
	}
	.b-pub__date {
		color: @white;
	}
	.b-pub__source {
		color: @white;
	}
}


.b-pub_type_image + .b-pub_type_border {

	&::before {
		display: none;
	}
}



.no-touchevents {
	.b-pub_type_image {
		&:hover {
			.b-pub {
				&__image {
					transform: scale3d(1.2,1.2,1.2);
				}
				&__date {
					color: @white;
				}
				&__title {
					color: @white;
				}
			}
		}
	}
}
