.i-home-page {
	.b-footer {

		&__copy {
			._link {
				border-bottom-color: transparent;
			}
		}
	}
}

